<template>
  <v-card class="pa-4" flat>
    <v-row no-gutters align="center">
      <div>
        <v-tabs v-model="tab" center-active>
          <v-tab>
            K1 & K2
          </v-tab>
          <v-tab>
            K3 & K4
          </v-tab>
        </v-tabs>
      </div>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        class="caption"
        small
        depressed
        @click="openRatingForm = true"
      >
        {{ $t("score_rating.master_rating") }}
        <v-icon class="ml-2">mdi-plus</v-icon>
      </v-btn>
    </v-row>

    <ScoreRatingList
      :type="tab ? 'K3K4' : 'K1K2'"
      :tab="tab"
      :triggerData="getData"
      @setDataEdit="data => setEdit(data)"
    />

    <ScoreRatingForm
      :isOpen="openRatingForm"
      :close="(onlyClose, type) => closeForm(onlyClose, type)"
      :dataEdit="dataEdit"
    />
  </v-card>
</template>

<script>
import ScoreRatingList from "./scoreRatingList";
import ScoreRatingForm from "./scoreRatingForm";
import i18n from "@/i18n";

export default {
  metaInfo: {
    title: i18n.t("routes.score_rating"),
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    ScoreRatingList,
    ScoreRatingForm
  },
  data() {
    return {
      tab: 0,
      openRatingForm: false,
      dataEdit: {},
      getData: false
    };
  },
  methods: {
    setEdit(item) {
      this.dataEdit = item;
      this.openRatingForm = true;
    },
    closeForm(onlyClose, type) {
      this.getData = false;
      this.openRatingForm = false;
      this.dataEdit = {};

      if (!onlyClose) {
        let tabTemp = 0;
        if (type == "K3K4") tabTemp = 1;
        this.tab = tabTemp;
        this.getData = true;
        setTimeout(() => {
          this.getData = false;
        }, 200);
      }
    }
  }
};
</script>
