import request from "@/utils/request";

export function getScoreRating(data) {
  return request({
    method: "POST",
    url: "grade_score/list",
    data: data
  });
}

export function createScoreRating(data) {
  return request({
    method: "POST",
    url: "grade_score/create",
    data: data
  });
}

export function updateScoreRating(data) {
  return request({
    method: "POST",
    url: "grade_score/edit",
    data: data
  });
}

export function deleteScoreRating(data) {
  return request({
    method: "POST",
    url: "grade_score/delete",
    data: data
  });
}
