var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"transition":"scale-transition","persistent":"","max-width":"500px"},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"subtitle-1 font-weight-bold"},[_vm._v(_vm._s(_vm.dataEdit.id ? _vm.$t("score_rating.update_form") : _vm.$t("score_rating.create_form")))]),_c('v-divider'),_c('v-card-text',{staticClass:"pb-0"},[_c('v-form',{ref:"scoreRatingForm",attrs:{"lazy-validation":""}},[_c('v-row',{staticClass:"mt-6"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"rules":[v => !!v || _vm.$t('app.required')],"label":_vm.$t('score_rating.form.type'),"items":_vm.scoreTypeList,"item-value":"value","item-text":"label","dense":"","outlined":""},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"rules":[v => !!v || _vm.$t('app.required')],"label":_vm.$t('score_rating.form.min'),"name":"minRatingGrade","outlined":"","dense":""},on:{"keypress":_vm.isNumberOnly,"keyup":() => {
                    if (_vm.form.min == 100) _vm.form.min = 99;
                  }},model:{value:(_vm.form.min),callback:function ($$v) {_vm.$set(_vm.form, "min", $$v)},expression:"form.min"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"rules":[v => !!v || _vm.$t('app.required')],"label":_vm.$t('score_rating.form.max'),"name":"maxRatingGrade","outlined":"","dense":""},on:{"keypress":function($event){return _vm.isNumberOnly($event)},"change":() => {
                    if (_vm.form.max >= 100) _vm.form.max = 100;
                    else if (_vm.form.max <= _vm.form.min)
                      _vm.form.max = Number(_vm.form.min) + 1;
                  }},model:{value:(_vm.form.max),callback:function ($$v) {_vm.$set(_vm.form, "max", $$v)},expression:"form.max"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"rules":[v => !!v || _vm.$t('app.required')],"label":_vm.$t('score_rating.form.code'),"name":"ratingGrade","maxlength":"2","outlined":"","dense":""},on:{"keyup":() => {
                    _vm.form.kode = _vm.form.kode.toUpperCase();
                  }},model:{value:(_vm.form.kode),callback:function ($$v) {_vm.$set(_vm.form, "kode", $$v)},expression:"form.kode"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"rules":[v => !!v || _vm.$t('app.required')],"label":_vm.$t('score_rating.form.alias'),"name":"aliasRatingGrade","maxlength":"50","outlined":"","dense":""},model:{value:(_vm.form.alias),callback:function ($$v) {_vm.$set(_vm.form, "alias", $$v)},expression:"form.alias"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"rules":[v => !!v || _vm.$t('app.required')],"label":_vm.$t('score_rating.form.description'),"name":"descriptionRatingGrade","rows":"3","auto-grow":"","outlined":"","dense":""},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)],1)],1)],1),_c('v-divider',{staticClass:"my-2"}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.closeHandler('onlyCLose')}}},[_vm._v(_vm._s(_vm.$t("app.cancel")))]),_c('v-btn',{staticClass:"gradient-primary",attrs:{"loading":_vm.loadingSave,"depressed":"","dark":""},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t("app.save")))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }