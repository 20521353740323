<template>
  <v-row justify="center">
    <v-dialog
      v-model="isOpen"
      transition="scale-transition"
      persistent
      max-width="500px"
    >
      <v-card outlined>
        <v-card-title class="subtitle-1 font-weight-bold">{{
          dataEdit.id
            ? $t("score_rating.update_form")
            : $t("score_rating.create_form")
        }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <v-form ref="scoreRatingForm" lazy-validation>
            <v-row class="mt-6">
              <v-col cols="12">
                <v-select
                  v-model="form.type"
                  :rules="[v => !!v || $t('app.required')]"
                  :label="$t('score_rating.form.type')"
                  :items="scoreTypeList"
                  item-value="value"
                  item-text="label"
                  dense
                  outlined
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="form.min"
                  :rules="[v => !!v || $t('app.required')]"
                  :label="$t('score_rating.form.min')"
                  name="minRatingGrade"
                  outlined
                  dense
                  @keypress="isNumberOnly"
                  @keyup="
                    () => {
                      if (form.min == 100) form.min = 99;
                    }
                  "
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="form.max"
                  :rules="[v => !!v || $t('app.required')]"
                  :label="$t('score_rating.form.max')"
                  name="maxRatingGrade"
                  outlined
                  dense
                  @keypress="isNumberOnly($event)"
                  @change="
                    () => {
                      if (form.max >= 100) form.max = 100;
                      else if (form.max <= form.min)
                        form.max = Number(form.min) + 1;
                    }
                  "
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="form.kode"
                  :rules="[v => !!v || $t('app.required')]"
                  :label="$t('score_rating.form.code')"
                  name="ratingGrade"
                  maxlength="2"
                  outlined
                  dense
                  @keyup="
                    () => {
                      form.kode = form.kode.toUpperCase();
                    }
                  "
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="form.alias"
                  :rules="[v => !!v || $t('app.required')]"
                  :label="$t('score_rating.form.alias')"
                  name="aliasRatingGrade"
                  maxlength="50"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="form.description"
                  :rules="[v => !!v || $t('app.required')]"
                  :label="$t('score_rating.form.description')"
                  name="descriptionRatingGrade"
                  rows="3"
                  auto-grow
                  outlined
                  dense
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider class="my-2"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="primary" @click="closeHandler('onlyCLose')">{{
            $t("app.cancel")
          }}</v-btn>
          <v-btn
            class="gradient-primary"
            :loading="loadingSave"
            depressed
            dark
            @click="save"
            >{{ $t("app.save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import i18n from "@/i18n";
import { isNumberOnly } from "@/utils/validate";

import moment from "moment/moment";
moment.locale("id");

import {
  createScoreRating,
  updateScoreRating
} from "@/api/admin/master/scoreRating";

export default {
  props: {
    isOpen: Boolean,
    close: Function,
    dataEdit: Object
  },
  data() {
    return {
      loadingSave: false,
      scoreTypeList: [
        { value: "K1K2", label: "K1 / K2" },
        { value: "K3K4", label: "K3 / K4" }
      ],
      form: {
        type: "",
        min: "",
        max: "",
        kode: "",
        alias: "",
        description: ""
      }
    };
  },
  watch: {
    async isOpen(val) {
      if (val) {
        this.form = {
          type: "",
          min: "",
          max: "",
          kode: "",
          alias: "",
          description: ""
        };

        if (this.dataEdit.id) {
          const d = this.dataEdit;
          this.form = {
            type: d.type,
            min: Number(d.min) == 0 ? "0" : Number(d.min),
            max: Number(d.max),
            kode: d.kode,
            alias: d.alias,
            description: d.description
          };
        }
      } else this.$refs.scoreRatingForm.reset();
    },
    "form.grade"(newVal) {
      if (newVal) this.getClassList();
    }
  },
  methods: {
    isNumberOnly: evt => isNumberOnly(evt),
    save() {
      if (this.$refs.scoreRatingForm.validate()) {
        const data = this.form;
        this.loadingSave = true;
        const newForm = {
          type: data.type,
          min: data.min,
          max: data.max,
          kode: data.kode,
          alias: data.alias,
          description: data.description
        };

        if (this.dataEdit.id) {
          updateScoreRating({ ...newForm, id: this.dataEdit.id })
            .then(res => {
              if (res.data.code) {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: i18n.t("score_rating.success_update"),
                  color: "success"
                });
                this.closeHandler();
              } else {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: res.data.message,
                  color: "error"
                });
              }
              this.loadingSave = false;
            })
            .catch(() => {
              this.loadingSave = false;
            });
        } else {
          createScoreRating(newForm)
            .then(res => {
              if (res.data.code) {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: i18n.t("score_rating.success_create"),
                  color: "success"
                });
                this.closeHandler();
              } else {
                this.$store.commit("CALL_SNACKBAR", {
                  msg: res.data.message,
                  color: "error"
                });
              }
              this.loadingSave = false;
            })
            .catch(() => {
              this.loadingSave = false;
            });
        }
      }
    },
    closeHandler(onlyCLose) {
      this.$nextTick(() => {
        this.close(onlyCLose, this.form.type);
      });
    }
  }
};
</script>

<style></style>
