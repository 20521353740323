<template>
  <div class="mt-3">
    <v-data-table
      :headers="tableHeaders"
      :items="dataTable"
      :loading="loading"
      :page.sync="queryData.page"
      :items-per-page="queryData.limit"
      disable-pagination
      hide-default-footer
      class="elevation-0"
    >
      <template v-slot:[`item.min`]="{ item }">
        {{ `${Number(item.min)} - ${Number(item.max)}` }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          icon
          small
          color="primary"
          :disabled="loading"
          @click="editRating(item)"
        >
          <v-icon small>mdi-pencil-box-outline</v-icon>
        </v-btn>
        <v-btn icon small color="error" @click="setDeleteRating(item)">
          <v-icon small>mdi-delete-forever</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <Pagination
      :length="length"
      :total="total"
      :current-page="queryData.page"
      :limit="queryData.limit"
      :handler="paginateChange"
      class="ma-3"
    />

    <ModalConfirm
      :title="$t('score_rating.delete_confirm')"
      :close="() => (this.confirmDelete = false)"
      :loading="loadingBtn"
      :isOpen="confirmDelete"
      :save="deleteScoreRating"
    />
  </div>
</template>

<script>
import i18n from "@/i18n";
import {
  getScoreRating,
  deleteScoreRating
} from "@/api/admin/master/scoreRating";

import Pagination from "@/components/Pagination";
import ModalConfirm from "@/components/ModalConfirm";

export default {
  props: {
    type: String,
    tab: [String, Number],
    triggerData: Boolean
  },
  components: {
    Pagination,
    ModalConfirm
  },
  data() {
    return {
      tableHeaders: [
        {
          text: i18n.t("score_rating.table.rating"),
          align: "left",
          value: "kode"
        },
        {
          text: i18n.t("score_rating.table.score"),
          value: "min"
        },
        {
          text: i18n.t("score_rating.table.description"),
          sortable: false,
          value: "description"
        },
        {
          text: i18n.t("core_competencies.table.action"),
          sortable: false,
          value: "action",
          width: 150
        }
      ],
      dataTable: [],
      loading: false,
      length: 0,
      total: 0,
      queryData: {
        page: 1,
        limit: 10
      },
      confirmDelete: false,
      loadingBtn: false,
      selectedRating: null
    };
  },
  watch: {
    tab() {
      this.getData();
    },
    triggerData(n) {
      if (n) this.getData();
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      const query = {
        filter: {
          type: [this.type]
        },
        orders: {
          type: "DESC",
          kode: "ASC"
        },
        ...this.queryData
      };
      this.loading = true;
      getScoreRating(query)
        .then(res => {
          const r = res.data;
          if (r.code) {
            this.dataTable = r.data.data;
            this.total = r.data.total;
            this.length = r.data.last_page;
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    paginateChange(page, limit) {
      this.queryData.page = page;
      this.queryData.limit = limit;
      this.getData();
    },
    editRating(item) {
      this.$emit("setDataEdit", item);
    },
    setDeleteRating(item) {
      this.selectedRating = item;
      this.confirmDelete = true;
    },
    deleteScoreRating() {
      if (this.selectedRating) {
        this.loadingBtn = true;
        deleteScoreRating({ id: this.selectedRating.id })
          .then(res => {
            if (res.data.code) {
              this.$store.commit("CALL_SNACKBAR", {
                msg: i18n.t("score_rating.success_update"),
                color: "success"
              });
              this.selectedRating = null;
              this.confirmDelete = false;
              this.getData();
            } else {
              this.$store.commit("CALL_SNACKBAR", {
                msg: res.data.message,
                color: "error"
              });
            }
            this.loadingBtn = false;
          })
          .catch(() => {
            this.loadingBtn = false;
          });
      }
    }
  }
};
</script>
